import { useControls, button } from 'leva';
import { useRef, useState, useEffect } from 'react';
import { useStore } from './stores/UseStore';
import DisplaySpectrum from './DisplaySpectrum.jsx';

const FFT_SIZE = 64; // min 32

export default function AudioInput() {
  const { buttonMicOn, displaySpectrum, audioAmplification } = useControls('Mic On', {
    'Mic On': button(() => {
      createStreamSource();
    }),
    audioAmplification: {
      value: 1,
      min: 0,
      max: 2,
      step: 0.1,
    },
    displaySpectrum: false,
  });

  const audioContext = useRef(null);
  const [analyserNode, setAnalyserNode] = useState(null);

  // Global State & Actions
  const audioArrayData = useStore((state) => state.audioArrayData);
  const setAudioArrayData = useStore((state) => state.setAudioArrayData);

  const createStreamSource = async () => {
    try {
      const streamSource = await navigator.mediaDevices.getUserMedia({audio: true});
      audioContext.current = new AudioContext();
      const audioSourceNode = audioContext.current.createMediaStreamSource(streamSource);

      const analyserNode = audioContext.current.createAnalyser();
      analyserNode.fftSize = FFT_SIZE*2;
      audioSourceNode.connect(analyserNode);

      setAnalyserNode(analyserNode);
    } catch (error) {
      console.error('マイクのアクセスに失敗しました:', error);
    }
  }

  useEffect(() => {
    if(analyserNode) {
      const bufferLength = analyserNode.frequencyBinCount;
      const audioArrayData = new Uint8Array(bufferLength);
      const amplifiedData = new Uint8Array(bufferLength);

      let animationFrameId;

      function update() {
        analyserNode.getByteFrequencyData(audioArrayData);
        
        for (let i = 0; i < bufferLength; i++) {
          amplifiedData[i] = Math.min(audioArrayData[i] * audioAmplification, 255);
        }
        setAudioArrayData(amplifiedData);

        animationFrameId = requestAnimationFrame(update);
      }
      
      update();

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [analyserNode, audioAmplification]);

  return (
    <>
      {displaySpectrum && <DisplaySpectrum />}
    </>
  )
}