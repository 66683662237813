import { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { ContactShadows, Grid, OrbitControls } from '@react-three/drei';
import { useControls } from 'leva';
import { Perf } from 'r3f-perf';
import Light from './Light';
import { useStore } from '../../common/stores/UseStore';

const OBJECT_COUNT = 32;

export default function AudioSample1() {
  // Global State
  const audioArrayData = useStore((state) => state.audioArrayData);
 
  // def ref
  const refCube = useRef();
  const refGrid = useRef();
  const refGroup = useRef();

  // for audio
  const [audioVolume, setAudioVolume] = useState(0);

  const updateAudioData = (audioData) => {
    const peak = audioData.reduce((max, sample) => {
      const currentValue = Math.abs(sample);
      return max > currentValue ? max : currentValue;
    })
    const rePeak = peak / 255;
    // const rePeak = dataArray[0] / 255;
    setAudioVolume(rePeak);

    // update mesh audio object
    for(let i = 0; i < refGroup.current.children.length; i++) {
      const audioIndex = Math.floor(i * (audioData.length / OBJECT_COUNT));
      const audioValue = audioData[audioIndex] / 255;
      // console.log(audioValue);

      refGroup.current.children[i].position.y = audioValue * 2;
    }
  }

  useFrame((state, delta) => {
    refCube.current.rotation.y -= delta * 0.2;
    refCube.current.rotation.z -= delta * 0.2;
    refGrid.current.rotation.y += delta * 0.1;
    refGroup.current.rotation.y += delta * 0.1;
  
    if(audioArrayData) {
      updateAudioData(audioArrayData);
    }
  });

  return <>
    <OrbitControls makeDefault />

    <Light intensity={audioVolume} />

    <ContactShadows
      position={[0, -0.99, 0 ]}
      scale={10}
      color={'#000000'}
      opacity={0.5}
      blur={2}
    />

    {/* <color args={ ['#FFFFFF' ]} attach="background" /> */}

    <mesh ref={refCube} castShadow position-y={2} scale={0.5 + Math.pow(audioVolume, 5)} >
      <boxGeometry />
      <meshStandardMaterial color={[0.5, 0.5, 0.5]} />
    </mesh>

    {/* <mesh receiveShadow position-y={ - 1 } rotation-x={ - Math.PI * 0.5 } scale={ 10 }>
      <planeGeometry />
      <meshStandardMaterial color="greenyellow" />
    </mesh> */}
    <group ref={refGroup}>
      {Array.from({length: OBJECT_COUNT}, (_, index) =>
        <mesh key={index} position={[(index/OBJECT_COUNT-0.5)*15, 0, 0]} scale={0.05}>
          <sphereGeometry />
          <meshStandardMaterial color="purple"/>
        </mesh>
      )}
    </group>

    <Grid ref={refGrid} position={[0, 0, 0]}  infiniteGrid={true} fadeDistance={20} />
  </>
}