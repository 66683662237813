import { useMemo, useEffect, useState, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useFrame, useThree } from "@react-three/fiber";
import { useFBX, Html, useAnimations } from "@react-three/drei";
import { VRMHumanBoneName, VRMLoaderPlugin, VRMUtils } from '@pixiv/three-vrm';
import { loadMixamoAnimation } from '../../common/loadMixamoAnimation';
import * as THREE from 'three';
import { useControls } from "leva";

export default function VRMAvatar(props) {
  const [gltf, setGltf] = useState();
  const [progress, setProgress] = useState(0);
  const avatar = useRef();
  const {scene, camera} = useThree();
  const [animFBX, setAnimFBX] = useState();
  const [mixer, setMixer] = useState();
  const setAnim = useControls('Animation', {
    setAnim: { options: ['0', '1'] }
    // setAnim: { options: listAnimation }
  });

  const isFirstRender = useRef(true);

  useEffect(() => {
    const loader = new GLTFLoader();
      loader.register((parser) => {
        return new VRMLoaderPlugin(parser);
      });
    
    loader.load(
      "/AliciaSolid.vrm",
      (tmpGltf) => {
        console.log(tmpGltf.scene);
        const currentVrm = tmpGltf.userData.vrm;
        VRMUtils.rotateVRM0(currentVrm);
        avatar.current = currentVrm;
        currentVrm.lookAt.target = camera;

        setGltf(currentVrm)
      },
      // called as loading progresses
      (xhr) => { setProgress((xhr.loaded / xhr.total) * 100) },
      (error) => { console.log(error) }
    )
  }, []);

  useEffect(() => {
    if(gltf) {
      const anim1 = loadMixamoAnimation('./Walking.fbx', gltf, 'walk');
      const anim2 = loadMixamoAnimation('./Running.fbx', gltf, 'run');

      Promise.all([anim1, anim2])
        .then((clips) => {  
          setAnimFBX(clips);
        })
        .catch((error)=> console.log(error));
    }
  }, [gltf]);

  useEffect(() => {
    if(animFBX){
      // console.log(animFBX);
      const currentMixer = new THREE.AnimationMixer(gltf.scene);
      currentMixer.clipAction(animFBX[setAnim.setAnim]).play();
      setMixer(currentMixer);
    }
  }, [animFBX]);

  useEffect(() => {
    if(isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const action = mixer.clipAction(animFBX[setAnim.setAnim]);
    action.reset().fadeIn(0.5).play();
    setMixer(mixer);

    return () => {
      action.fadeOut(0.5);
    }
  }, [setAnim])

  useFrame(({clock}, delta) => {
    // const time = clock.getElapsedTime();
    if(avatar.current) avatar.current.update(delta);
    if(mixer) mixer.update(delta);
  })

  return (
    <>
      {gltf ? (
        <primitive object={gltf.scene} />
      ) : (
        <Html center>{progress}</Html>
      )}
    </>
  );  
}