import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import AudioSample1 from './r3f/AudioSample1.jsx';
import AudioSample2 from './r3f/AudioSample2.jsx';
import AudioSample3 from './r3f/AudioSample3.jsx';
import { useControls, button } from 'leva';
import { Perf } from 'r3f-perf';
import AudioSample4 from './r3f/AudioSample4.jsx';
import AudioSample5 from './r3f/AudioSample5.jsx';

export default function Visualizer() {
  const  { perfVisible } = useControls('Performance', {
    perfVisible: true,
  });
  const { selectComponent } = useControls('Select Visualizer', {
      selectComponent: {
        options: ['AudioSample5', 'AudioSample1', 'AudioSample2', 'AudioSample3', 'AudioSample4'],
    },
  });

  return (
    <Canvas
      shadows
      camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: [5, 4, -4],
      }}
      style={{
        background: '#000000'
      }}
      gl={{
        toneMapping: THREE.ACESFilmicToneMapping,
        toneMappingExposure: 1.0,
      }}
    >
      {perfVisible ? <Perf position="top-left" /> : null}
      {selectComponent === 'AudioSample1' && <AudioSample1 />}
      {selectComponent === 'AudioSample2' && <AudioSample2 />}
      {selectComponent === 'AudioSample3' && <AudioSample3 />}
      {selectComponent === 'AudioSample4' && <AudioSample4 />}
      {selectComponent === 'AudioSample5' && <AudioSample5 />}
    </Canvas>
  )
}
