import { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { CameraControls, Environment, Lightformer } from '@react-three/drei';
import { EffectComposer, N8AO, Bloom } from '@react-three/postprocessing';
import { Physics, RigidBody, BallCollider } from '@react-three/rapier';
import { useStore } from '../../common/stores/UseStore';

function Pointer({vec = new THREE.Vector3()}) {
  // Global State
  const audioArrayData = useStore((state) => state.audioArrayData);

  const refPointer = useRef();
  
  useFrame(({pointer, viewport, clock}) => {
    // const moveRadius = 1;
    const moveRadius = audioArrayData ? Math.pow(audioArrayData[0]/255, 4) * 2 : 0.1;
    // const moveSpeed = audioArrayData ? audioArrayData[0]/255 * 1 + 1 : 2;
    const moveSpeed = 6;
    
    // refPointer.current?.setNextKinematicTranslation(vec.set((pointer.x * viewport.width) / 2, (pointer.y * viewport.height) / 2, 0));
    refPointer.current?.setNextKinematicTranslation(
      vec.set(
        (Math.cos(clock.getElapsedTime() * moveSpeed) * moveRadius), 
        (Math.sin(clock.getElapsedTime() * moveSpeed) * moveRadius), 
        (Math.cos(clock.getElapsedTime() * moveSpeed*3) * moveRadius)));
  });

  return (
    <RigidBody ref={refPointer} type="kinematicPosition" colliders={false}>
      <BallCollider args={[0.3]} />
    </RigidBody>
  )
}

function Ball({
  position = [0, 0, 0], 
  scale = 0.2, 
  vec = new THREE.Vector3(), 
  color = new THREE.Color(0.0, 0.0, 1.0)
}) {
  const refBall = useRef();

  useFrame(() => {
    // vec.copy(～.translation())で現在位置を取得＆コピーして、negate()（ベクトル反転）することで中心に向かうベクトルに変換
    // multiplyScalar()でベクトルの大きさを調整、applyImpulse()でベクトルを適用
    refBall.current?.applyImpulse(vec.copy(refBall.current.translation()).negate().multiplyScalar(0.05));
  });

  return (
    <RigidBody ref={refBall} position={position} colliders="ball" linearDamping={2} angularDamping={1} friction={0.1}>
      <mesh receiveShadow castShadow>
        <sphereGeometry args={[scale, 32, 32]}/>
        <meshStandardMaterial color={color}  metalness={0} roughness={0.2}/>
      </mesh>
    </RigidBody>
  )
}

export default function AudioSample5() {
  const cameraControlsRef = useRef();

  useEffect(() => {
    if (cameraControlsRef.current) {
      cameraControlsRef.current.setPosition(0, 0, 5);
    }
  }, []);

  return (
    <>
      <CameraControls 
        ref={cameraControlsRef} 
        makeDefault 
        azimuthRotateSpeed={0}
        polarRotateSpeed={0}
      />
      <color attach="background" args={['#000000']} />

      {/* Lighing */}
      <ambientLight intensity={0.4} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={1} castShadow />
      <Environment resolution={256}>
        <group rotation={[-Math.PI / 3, 0, 1]}>
          <Lightformer form="circle" intensity={100} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={2} />
          <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={2} />
          <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={2} />
          <Lightformer form="circle" intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={8} />
          <Lightformer form="ring" color="#40ffcf" intensity={40} onUpdate={(self) => self.lookAt(0, 0, 0)} position={[20, 20, 0]} scale={10} />
        </group>
      </Environment>

      {/* Postprocessing */}
      <EffectComposer disableNormalPass multisampling={8}>
        <Bloom intensity={0.5} mipmapBlur={true} />
        <N8AO distanceFalloff={1} aoRadius={1} intensity={4} />
      </EffectComposer>

      {/* Objects */}
      <Physics debug={false} gravity={[0, 0, 0]}>
        <Pointer />
        {Array.from({length: 40}, (_, index) => {
          return (
            <Ball 
              key={index} 
              position={[(Math.random()-0.5) * 2, (Math.random()-0.5) * 2, (Math.random()-0.5) * 2]} 
              scale={Math.random() * 0.2 + 0.2} 
              color={new THREE.Color(`hsl(0, 0%, ${Math.random() > 0.2 ? 100 : 0}%)`)}
            />
          )
        })}
      </Physics>
    </>
  )
}
