import { useStore } from './stores/UseStore';
import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';

const FULL_HUE_DEGREES = 270;
const CANVAS_HEIGHT = 100;

export default function DisplaySpectrum() {
  const cssSpectrumArea = css({
    width: "100%",
    height: "100px",
    position: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#000",
    zIndex: 100,
    borderTop: "1px solid #fff",
  });
  const cssSpectrumText = css({
    width: "100%",
  });

  const cssSpectrum = css({
    width: "100%",
    height: "100%",  
  });
 

  const audioArrayData = useStore((state) => state.audioArrayData);
  const refSpectrum = useRef(null);
  const canvasCtx = useRef(null);

  const drawAudioGraph = (audioData) => {
    if (!refSpectrum.current || !canvasCtx.current) return;
    
    const canvas = refSpectrum.current;
    const ctx = canvasCtx.current;
    
    // キャンバスをクリア
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const barWidth = (canvas.width / audioData.length);
    
    // 水平方向の目盛り線（上下）
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)';
    for(let i = 0; i <= 4; i++) {
      const y = (canvas.height / 4) * i;
      ctx.moveTo(0, y);
      ctx.lineTo(canvas.width, y);
    }
    ctx.stroke();

    // スペクトラムとインデックス番号の描画
    for(let i = 0; i < audioData.length; i++) {
      const barHeight = (audioData[i] / 255) * canvas.height;
      const x = i * barWidth;
      const hue = (i / audioData.length) * FULL_HUE_DEGREES;
      
      ctx.fillStyle = `hsl(${hue}, 100%, 50%)`;
      ctx.fillRect(x, canvas.height, barWidth, -barHeight);
      
      // インデックス番号の描画
      ctx.fillStyle = '#fff';
      ctx.font = '8px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(i.toString(), x + barWidth/2, canvas.height - 10);
    }
  }

  useEffect(() => {
    if(audioArrayData) {
      canvasCtx.current = refSpectrum.current.getContext('2d');
      refSpectrum.current.width = window.innerWidth;
      refSpectrum.current.height = CANVAS_HEIGHT;

      let animationFrameId;
      
      const update = () => {
        drawAudioGraph(audioArrayData);
        animationFrameId = requestAnimationFrame(update);
      }
      
      update();
      
      // クリーンアップ関数を追加
      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [audioArrayData]);

  return (
    <div css={cssSpectrumArea}>
      {audioArrayData === null ? (
        <div css={cssSpectrumText}>no audio data</div>
      ) : (
        <>
          <canvas ref={refSpectrum} css={cssSpectrum}></canvas>
        </>
      )}
    </div>
  )
}