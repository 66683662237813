import * as THREE from 'three';
import { CameraControls, Grid } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useControls, button } from 'leva';
import { useRef, useState } from 'react';
import { useStore } from '../../common/stores/UseStore';

import waterShadingVertexShader from './shaders/waterShading/vertex.glsl';
import waterShadingFragmentShader from './shaders/waterShading/fragment.glsl';

export default function AudioSample3() {
  const { uBigWavesElevation } = useControls('Setting Waves Parameters', {
    uBigWavesElevation: {
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01
    },
  })
  const { uBigWavesFrequency } = useControls('Setting Waves Parameters', {
    uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
  })
  const { uBigWavesSpeed } = useControls('Setting Waves Parameters', {
    uBigWavesSpeed: {
      value: 0.75,
      min: 0,
      max: 4,
      step: 0.01
    },
  })
  const { uDepthColor, uSurfaceColor } = useControls('Setting Waves Parameters', {
    uDepthColor: '#967e7e',
    uSurfaceColor: '#000000',
  })
  const { uColorOffset, uColorMultiplier } = useControls('Setting Waves Parameters', {
    uColorOffset: {
      value: 0.925,
      min: 0,
      max: 1,
      step: 0.001
    },
    uColorMultiplier: {
      value: 1.0,
      min: 0,
      max: 10,
      step: 0.001
    },
  })
  const { uSmallWavesElevation, uSmallWavesFrequency, uSmallWavesSpeed, uSmallIterations } = useControls('Setting Waves Parameters', {
    uSmallWavesElevation: { 
      value: 0.15,
      min: 0,
      max: 1,
      step: 0.001
    },
    uSmallWavesFrequency: {
      value: 3.0,
      min: 0,
      max: 30,
      step: 0.001
    },
    uSmallWavesSpeed: {
      value: 0.2,
      min: 0,
      max: 4,
      step: 0.001
    },
    uSmallIterations: {
      value: 4.0,
      min: 0,
      max: 10,
      step: 1
    },  
  })
  const { uLightIntensity } = useControls('Setting Waves Parameters', {
    uLightIntensity: {
      value: 50.0,
      min: 0,
      max: 100,
      step: 0.01
    },
  })
  const { uLightPosition } = useControls('Setting Waves Parameters', {
    uLightPosition: { value: new THREE.Vector3(0.0, 4.0, 0.0) },
  })

  const waterShader = new THREE.ShaderMaterial({
    uniforms: {
      uTime: { value: 0 },
      uBigWavesElevation: { value: uBigWavesElevation },
      uBigWavesFrequency: { value: uBigWavesFrequency },
      uBigWavesSpeed: { value: uBigWavesSpeed },
      uDepthColor: { value: new THREE.Color(uDepthColor) },
      uSurfaceColor: { value: new THREE.Color(uSurfaceColor) },
      uColorOffset: { value: uColorOffset },
      uColorMultiplier: { value: uColorMultiplier },
      uSmallWavesElevation: { value: uSmallWavesElevation },
      uSmallWavesFrequency: { value: uSmallWavesFrequency },
      uSmallWavesSpeed: { value: uSmallWavesSpeed },
      uSmallIterations: { value: uSmallIterations },
      uLightIntensity: { value: uLightIntensity },
      uLightPosition: { value: uLightPosition },
    },
    vertexShader: waterShadingVertexShader,
    fragmentShader: waterShadingFragmentShader
  });

  const refObject = useRef();
  const refHelperLight = useRef();
  const refCameraControls = useRef();

  // Global State
  const audioArrayData = useStore((state) => state.audioArrayData);

  useFrame((state, delta) => {
    waterShader.uniforms.uTime.value += delta;
    refCameraControls.current.azimuthAngle += 10 * delta * THREE.MathUtils.DEG2RAD;

    const lightMoving = new THREE.Vector3(Math.sin(state.clock.elapsedTime * 0.5) * 2, 0.5, 0)
    waterShader.uniforms.uLightPosition.value = lightMoving;
    refHelperLight.current.position.set(lightMoving.x, lightMoving.y, lightMoving.z);

    if(audioArrayData) {
      const audioLevel = audioArrayData[10] / 255;
      waterShader.uniforms.uSmallWavesElevation.value = audioLevel * 0.3 + 0.0;
      waterShader.uniforms.uSmallWavesSpeed.value = audioLevel * 0.1 + 0.3;
      waterShader.uniforms.uLightIntensity.value = audioLevel * 100;
    }
  })

  return (
    <>
      <CameraControls ref={refCameraControls} makeDefault />

      <mesh ref={refObject} position={[0, 0.01, 0]} rotation-x={-Math.PI * 0.5} material={waterShader} side={THREE.DoubleSide}>
        <planeGeometry args={[4, 4, 512, 512]} deleteAttribute={'normal'} />
      </mesh>

      <mesh ref={refHelperLight} position={[0, 0.25, 0]}>
        <icosahedronGeometry args={[0.01, 2]}/>
        <meshBasicMaterial color="white" />
      </mesh>
      {/* 
      <mesh position={[0, 0.01, 0]} rotation-x={-Math.PI * 0.5} material={waterShader} side={THREE.DoubleSide}>
        <sphereGeometry args={[1, 128, 128]}/>
      </mesh> */}

      {/* <axesHelper args={[5]} position={[0, 0.25, 0]} /> */}
      {/* <Grid position={[0, 0, 0]}  infiniteGrid={true} fadeDistance={20} /> */}
    </>
  )
}