import VisualizerR3f from './components/VisualizerR3f.jsx';
import AudioInput from './common/AudioInput.jsx';

export default function App() {
  return (
    <>
      <AudioInput />
      <VisualizerR3f />
    </>
  )
}