uniform vec3 uDepthColor;
uniform vec3 uSurfaceColor;
uniform float uColorOffset;
uniform float uColorMultiplier;
varying float vElevation;
varying vec3 vNormal;
varying vec3 vPosition;
uniform float uLightIntensity;
uniform vec3 uLightPosition;

#include '../shading/directionalLight.glsl';
#include '../shading/pointLight.glsl';

void main() {
  vec3 viewDirection = normalize(vPosition - cameraPosition);
  vec3 normal = normalize(vNormal);

  // Bse color
  float mixStrength = (vElevation + uColorOffset) * uColorMultiplier;
  mixStrength = smoothstep(0.0, 1.0, mixStrength);
  vec3 color = mix(uDepthColor, uSurfaceColor, mixStrength);

  // Light
  vec3 light = vec3(0.0);
  light += directionalLight(
    vec3(0.0, 1.0, 1.0),  // light color
    1.0,                  // light intensity
    normal,               // normal
    vec3(-1.0, 0.5, 0.0), // light position
    viewDirection,        // view direction
    30.0                  // specular power
  ); 
  
  light += pointLight(
    vec3(1.0, 1.0, 1.0),  // light color
    uLightIntensity,                  // light intensity
    normal,               // normal
    uLightPosition, // light position
    viewDirection,        // view direction
    50.0,                  // specular power
    vPosition,            // position
    0.7                   // decay
  );
  
  color *= light;

  // Final 
  gl_FragColor = vec4(color, 1.0);
  // gl_FragColor = vec4(normal, 1.0);
  #include <tonemapping_fragment>
  #include <colorspace_fragment>
}