uniform float uTime;
varying vec3 vPosition;
varying vec3 vNormal;
uniform float uStrength;
#include "../_includes/random2D.glsl";

void main() {
  vec4 modelPosition = modelMatrix * vec4(position, 1.0);
  vec4 modelNormal = modelMatrix * vec4(normal, 0.0);

  // glitch
  float glitchStrength = sin(uTime*2.0 - modelPosition.y);
  glitchStrength = smoothstep(0.3, 1.0, glitchStrength);
  glitchStrength *= uStrength;
  modelPosition.x += (random2D(modelPosition.xz + uTime) - 0.5) * glitchStrength;
  modelPosition.z += (random2D(modelPosition.zx + uTime) - 0.5) * glitchStrength;
  modelPosition.y += (random2D(modelPosition.zx + uTime) - 0.5) * glitchStrength*0.1;

  // final position
  gl_Position = projectionMatrix  * viewMatrix * modelPosition;
  
  vPosition = modelPosition.xyz;
  vNormal = modelNormal.xyz;
}