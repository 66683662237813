import { create } from 'zustand';

export const useStore = create((set) => ({
  // sample zustand
  bears: 0,
  incrementBears: (inc) => set((state) => ({ bears: inc ? state.bears + inc : state.bears + 1 })),
  setBears: (amount) => set({ bears: amount }),
  resetBears: () => set({ bears: 0 }),

  // audio input
  audioArrayData: null,
  setAudioArrayData: (array) => set({ audioArrayData: array }),
}))