varying vec3 vPosition;
uniform float uTime;
varying vec3 vNormal;
uniform vec3 uColor;

void main() {
  float stripes = mod((vPosition.y - uTime*0.2) * 10.0, 1.0);
  stripes = pow(stripes, 2.0);

  vec3 viewDirection = normalize(vPosition - cameraPosition);
  vec3 normal = normalize(vNormal);
  if(!gl_FrontFacing) normal *= -1.0;
  
  float frenel = dot(viewDirection, normal) + 1.0;
  frenel = pow(frenel, 1.0);

  float falloff = smoothstep(1.2, 0.0, frenel);

  float holographic = stripes * frenel;
  holographic += frenel * 1.25;
  holographic *= falloff;


  // final color
  gl_FragColor = vec4(uColor, holographic);

  #include <tonemapping_fragment>
  #include <colorspace_fragment>
}