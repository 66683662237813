import { useEffect, useRef, useState } from 'react';
import { extend, useFrame } from '@react-three/fiber';
import { shaderMaterial, Grid, useGLTF, Sparkles, CameraControls } from '@react-three/drei';
import { useControls, button, buttonGroup, folder } from 'leva'
import Light from './Light';
import * as THREE from 'three';
import VRMAvatar from './VRMAvatar';
import BoxRoad from './BoxRoad';
import { useStore } from '../../common/stores/UseStore';

// import shaders
import holographicVertexShader from './shaders/holographic/vertex.glsl';
import holographicFragmentShader from './shaders/holographic/fragment.glsl';
import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing';

const TestShader = shaderMaterial({
    uTime: 0,
    uColor: new THREE.Color('blue'),
    uStrength: 0.5,
  },
  holographicVertexShader, 
  holographicFragmentShader
);
extend({TestShader});

export default function AudioSample2() {
  // setting leva
  const  { buttonMicOn } = useControls('debug', {
    'moveCamera': button(() => { 
      moveCamera();
    }),
  });

  // Global State
  const audioArrayData = useStore((state) => state.audioArrayData);

  const moveCamera = () => {
    const random = (Math.random()-0.5) * 2;
    const cameraPos = [random*5+2, (random + 1.5)*1.5, random*5+2, 0, 1, 0];
    refCameraControl.current
      .setLookAt(cameraPos[0], cameraPos[1],cameraPos[2], cameraPos[3], cameraPos[4], cameraPos[5], true);
  }

  // def ref
  // const refObject = useRef();
  // const refGrid = useRef();
  const refCameraControl = useRef();

  const materialHolographic = new TestShader({
    transparent: true,
    side: THREE.DoubleSide,
    depthWrite: false,
    // blending: THREE.AdditiveBlending,
  });

  useEffect(() => {
    refCameraControl.current.setLookAt(0, 2,3, 0, 1, 0, false);
  }, [])

  let isExe = false;
  const threshold = 0.5; // range: 0~1.0

  useFrame((state, delta) => {
    materialHolographic.uTime += delta;
    // refCameraControl.current.azimuthAngle += 20 * delta * THREE.MathUtils.DEG2RAD;

    // move camera
    if(audioArrayData) {
      const audioLevel = audioArrayData[10] / 255;
      if(audioLevel > threshold) {
        if(!isExe) {
          moveCamera();
          isExe = true;
        }
      } else {
        isExe = false;
      }
    }
  })

  return <>
    {/* カメラ */}
    <CameraControls ref={refCameraControl} makeDefault smoothTime={0.1}  />

    {/* ライト */}
    <Light />

    <EffectComposer>
      <Bloom intensity={1.0} mipmapBlur={true} />
      <Vignette  offset={0.3} darkness={0.5} />
    </EffectComposer>

    {/* <group ref={refObject}>
      <group dispose={null} scale={3} position={[0, -2.5, 0]}>
        <primitive object={nodes.Hips} />
        <mesh geometry={nodes.characterblendshapes1.geometry}  material={materialHolographic} >
        </mesh>
        <mesh geometry={nodes.characterblendshapes1_1.geometry} >
          <meshStandardMaterial color={[0.9, 0.9, 0.9]} />
        </mesh>
        <mesh geometry={nodes.characterblendshapes1_2.geometry}>
        </mesh>
      </group>
      <Grid position={[0, -2.5, 0]} infiniteGrid={true} fadeDistance={20} />
    </group> */}

    <VRMAvatar material={materialHolographic} />
  
    <Sparkles count={100} scale={10} size={3} speed={1} />

    <BoxRoad />
  </>
}
