import {useRef} from 'react';
import {useFrame} from '@react-three/fiber';
import { useStore } from '../../common/stores/UseStore';

export default function BoxRoad() {
  const refBox = useRef();
  const speed = 0.02;
  const loopLength = 16;

  // Global State
  const audioArrayData = useStore((state) => state.audioArrayData);

  useFrame((state, delta) => {
    // console.log(audioData);
    refBox.current.children.forEach((box, i) => {
      // z位置の更新
      box.position.z -= speed;
      if (box.position.z < -loopLength/2) {
        box.position.z = loopLength/2;
      }

      // 音声データに基づいて高さを更新
      if (audioArrayData) {
        const audioIndex = Math.floor(i * (audioArrayData.length / 32));
        const audioValue = audioArrayData[audioIndex] / 255;

        // スケールを更新（最小0.05、最大2.0）
        box.scale.y = 0.05 + audioValue * 2;
        // Y位置を固定（ボックスの上端を基準に）
        box.position.y = -(box.scale.y / 2) + 0.025;
      }
    });
  });

  return <>
     <group ref={refBox}>
      {Array.from({length: 32}, (_, index) => 
        <mesh   
          key={index} 
          position={[0, 0.025, (index * 0.5) - 8]}
          scale={[1, 0.05, 0.3]}
        >
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial 
           color={`hsl(${index*11.25}, 100%, 75%)`}
           emissive={`hsl(${index*11.25}, 100%, 80%)`}  // 発光効果
           emissiveIntensity={0.5}
          />
        </mesh>
      )}
    </group>
  </>
}